@charset "UTF-8";
/**
 * File: styles.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * Imports
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: buttons.scss
 *
 * Option:
 * Author:
 * License:
 *
 **/
/**
 * Imports
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/**
 * Variables
 **/
/**
 * Google Fonts styles element
 **/
@import url("https://fonts.googleapis.com/css?family=Oswald:400,500|Rambla:400,700");
/**
 * Styles element
 **/
.smoothed {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

b,
strong {
  color: #101010;
  font-weight: bold; }

.text-muted {
  color: #6c757d !important; }

a {
  color: #5584ff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #5584ff;
    text-decoration: underline; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #101010;
  font-family: inherit;
  font-weight: 500; }

.h1,
h1 {
  font-size: 24px;
  line-height: 1.3; }
  @media screen and (min-width: small) {
    .h1,
    h1 {
      font-size: 26px; } }
  @media screen and (min-width: medium) {
    .h1,
    h1 {
      font-size: 36px;
      line-height: 1.5; } }
  @media screen and (min-width: 900px) {
    .h1,
    h1 {
      font-size: 46px; } }
  @media screen and (min-width: large) {
    .h1,
    h1 {
      font-size: 58px;
      line-height: 1.75; } }
  @media screen and (min-width: 1440px) {
    .h1,
    h1 {
      font-size: 64px; } }

.h2,
h2 {
  font-size: 22px;
  line-height: 1.3; }
  @media screen and (min-width: small) {
    .h2,
    h2 {
      font-size: 24px; } }
  @media screen and (min-width: medium) {
    .h2,
    h2 {
      font-size: 25px;
      line-height: 1.5; } }
  @media screen and (min-width: 900px) {
    .h2,
    h2 {
      font-size: 26px; } }
  @media screen and (min-width: large) {
    .h2,
    h2 {
      font-size: 27px;
      line-height: 1.75; } }
  @media screen and (min-width: 1440px) {
    .h2,
    h2 {
      font-size: 28px; } }

.h3,
h3 {
  font-size: 20px;
  line-height: 1.3; }
  @media screen and (min-width: small) {
    .h3,
    h3 {
      font-size: 22px; } }
  @media screen and (min-width: medium) {
    .h3,
    h3 {
      font-size: 23px;
      line-height: 1.5; } }
  @media screen and (min-width: 900px) {
    .h3,
    h3 {
      font-size: 24px; } }
  @media screen and (min-width: large) {
    .h3,
    h3 {
      font-size: 25px;
      line-height: 1.75; } }
  @media screen and (min-width: 1440px) {
    .h3,
    h3 {
      font-size: 26px; } }

.h4,
h4 {
  font-size: 18px;
  line-height: 1.3; }
  @media screen and (min-width: small) {
    .h4,
    h4 {
      font-size: 20px; } }
  @media screen and (min-width: medium) {
    .h4,
    h4 {
      font-size: 21px;
      line-height: 1.5; } }
  @media screen and (min-width: 900px) {
    .h4,
    h4 {
      font-size: 22px; } }
  @media screen and (min-width: large) {
    .h4,
    h4 {
      font-size: 23px;
      line-height: 1.75; } }
  @media screen and (min-width: 1440px) {
    .h4,
    h4 {
      font-size: 24px; } }

.h5,
h5 {
  font-size: 16px;
  line-height: 1.3; }
  @media screen and (min-width: small) {
    .h5,
    h5 {
      font-size: 18px; } }
  @media screen and (min-width: medium) {
    .h5,
    h5 {
      font-size: 19px;
      line-height: 1.5; } }
  @media screen and (min-width: 900px) {
    .h5,
    h5 {
      font-size: 20px; } }
  @media screen and (min-width: large) {
    .h5,
    h5 {
      font-size: 21px;
      line-height: 1.75; } }
  @media screen and (min-width: 1440px) {
    .h5,
    h5 {
      font-size: 22px; } }

.h6,
h6 {
  font-size: 16px;
  line-height: 1.3; }
  @media screen and (min-width: small) {
    .h6,
    h6 {
      font-size: 18px; } }
  @media screen and (min-width: medium) {
    .h6,
    h6 {
      font-size: 19px;
      line-height: 1.4; } }
  @media screen and (min-width: 900px) {
    .h6,
    h6 {
      font-size: 20px; } }
  @media screen and (min-width: large) {
    .h6,
    h6 {
      font-size: 21px;
      line-height: 1.45; } }
  @media screen and (min-width: 1440px) {
    .h6,
    h6 {
      font-size: 22px; } }

p {
  color: #070f44;
  margin: 0 0 1.2rem;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400; }
  @media screen and (min-width: small) {
    p {
      font-size: 16px; } }
  @media screen and (min-width: medium) {
    p {
      font-size: 17px;
      line-height: 1.5; } }
  @media screen and (min-width: 900px) {
    p {
      font-size: 18px; } }
  @media screen and (min-width: large) {
    p {
      font-size: 20px;
      line-height: 1.75; } }
  @media screen and (min-width: 1440px) {
    p {
      font-size: 22px; } }

ul {
  color: #070f44;
  padding: 0;
  /* Убираем поля */
  font-family: inherit;
  font-size: 16px;
  line-height: 1.4; }
  @media screen and (min-width: small) {
    ul {
      font-size: 16px; } }
  @media screen and (min-width: medium) {
    ul {
      font-size: 17px;
      line-height: 1.5; } }
  @media screen and (min-width: 900px) {
    ul {
      font-size: 18px; } }
  @media screen and (min-width: large) {
    ul {
      font-size: 20px;
      line-height: 1.75; } }
  @media screen and (min-width: 1440px) {
    ul {
      font-size: 22px; } }

.content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #5f687b; }

.content ul {
  list-style: none;
  padding: 0; }
  .content ul li {
    padding-bottom: 0.5rem; }
  .content ul i {
    padding-right: 0.25rem;
    color: #5584ff; }

.content p:last-child {
  margin-bottom: 0; }

/**
 * Font-face
 **/
::selection {
  color: #6c757d;
  background: rgba(0, 0, 0, 0.1); }

::-webkit-scrollbar {
  width: 0px; }

::-webkit-scrollbar-track {
  background: #fff; }

::-webkit-scrollbar-thumb {
  background: #101010; }

::-webkit-scrollbar-thumb:hover {
  background: #101010; }

/**
 * Font-face
 **/
/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/**
 * File: site.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * Imports
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * Variables
 **/
/**
 * Styles element
 **/
body,
html {
  margin: 0;
  padding: 0; }

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

/**
 * Typography
 **/
html {
  font-family: Poppins, Arial, system-ui, -apple-system, sans-serif;
  font-family: "Source Serif Pro", serif !important;
  line-height: 1.8; }

body {
  font-family: inherit;
  line-height: inherit;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.progress-cntainer {
  background-color: black;
  height: 15px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%; }
  .progress-cntainer .progress {
    background-color: #00cc83;
    height: 15px;
    width: 15%; }

/**
 * File: sections.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * Imports
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles element
 **/
.section {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  box-sizing: border-box; }

.addo-references,
.addo-testimonials,
.addo-skills,
.addo-blog,
.addo-post,
.addo-projects,
.addo-recent-projects,
.addo-team,
.addo-about,
.addo-contact,
.section-about,
.section-services,
.section-skills,
.section-brands,
.section-letscontact,
.section-contact,
.section-blog-preview,
.section-experience,
.section-services-app1c,
.section-services-web {
  padding-top: 60px;
  padding-bottom: 60px;
  clear: both;
  width: 100%;
  display: block;
  background-color: #fff; }

.section-title-meta {
  display: block;
  color: #070f44;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 5px;
  text-transform: uppercase; }
  .section-title-meta::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #5584ff;
    margin: 4px 10px; }

.section-title {
  margin-bottom: 2rem;
  color: #101010;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase; }

.section-subtitle {
  margin-bottom: 1rem;
  color: #101010; }

/**
 * File: home.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * Imports
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles element
 **/
.page-home {
  background-color: #fafafa !important; }

.bg-snow-white {
  background-color: #fafafa !important; }

#addo-page,
.page-portfolio {
  width: 100%;
  position: relative; }

#addo-main,
.portfolio-main {
  float: right;
  width: 100%;
  transition: 0.5s; }
  @media (min-width: 768.02px) {
    #addo-main,
    .portfolio-main {
      width: min(80%, calc(100% - 300px)); } }
  @media (min-width: 1800px) {
    #addo-main,
    .portfolio-main {
      width: calc(100% - 480px); } }

.page-portfolio.offcanvas {
  overflow-x: hidden; }

.page-portfolio.offcanvas .portfolio-main,
.page-portfolio.offcanvas .addo-nav-toggle {
  top: 0;
  transform: translateX(370px); }

@media (min-width: 768.02px) {
  #addo-main .container-fluid,
  .portfolio-main .container-fluid {
    padding-right: 1rem;
    padding-left: 1rem; } }

@media (min-width: 1800px) {
  #addo-main .container-fluid,
  .portfolio-main .container-fluid {
    padding-right: 3rem;
    padding-left: 3rem; } }

.addo-nav-toggle {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
  cursor: pointer;
  opacity: 1;
  visibility: hidden;
  padding: 12px 15px;
  transition: 0.5s;
  background: #101010;
  margin: 15px 30px;
  border-radius: 50%;
  cursor: pointer;
  text-decoration: none; }
  .addo-nav-toggle a:link {
    text-decoration: none; }
  @media screen and (max-width: 768px) {
    .addo-nav-toggle {
      opacity: 1;
      visibility: visible; } }

.addo-nav-toggle {
  cursor: pointer;
  text-decoration: none; }
  .addo-nav-toggle.active i::before, .addo-nav-toggle.active i::after {
    background: #fff; }
  .addo-nav-toggle.dark.active i::before, .addo-nav-toggle.dark.active i::after {
    background: #101010; }
  .addo-nav-toggle:hover, .addo-nav-toggle:focus, .addo-nav-toggle:active {
    outline: none;
    border-bottom: none !important; }
  .addo-nav-toggle i {
    position: relative;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    width: 20px;
    height: 1px;
    color: #fff;
    font: bold 14px/0.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #fff;
    transition: all 0.2s ease-out;
    top: -2px; }
    .addo-nav-toggle i::before, .addo-nav-toggle i::after {
      content: "";
      width: 20px;
      height: 1px;
      background: #fff;
      position: absolute;
      left: 0;
      transition: 0.2s; }
  .addo-nav-toggle.dark i {
    position: relative;
    color: #101010;
    background: #101010;
    transition: all 0.2s ease-out; }
    .addo-nav-toggle.dark i::before, .addo-nav-toggle.dark i::after {
      background: #101010;
      transition: 0.2s; }
  .addo-nav-toggle i::before {
    top: -7px; }
  .addo-nav-toggle i::after {
    bottom: -7px; }
  .addo-nav-toggle:hover i::before {
    top: -10px; }
  .addo-nav-toggle:hover i::after {
    bottom: -10px; }
  .addo-nav-toggle.active i {
    background: transparent; }
    .addo-nav-toggle.active i::before {
      top: 0;
      transform: rotateZ(45deg); }
    .addo-nav-toggle.active i::after {
      bottom: 0;
      transform: rotateZ(-45deg); }

/**
 * File: footer.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles element
 **/
.footer-top {
  padding-top: 8rem;
  padding-bottom: 4rem;
  color: #fff;
  background: #1e202e;
  text-align: center; }
  .footer-top-title {
    color: #fff;
    font-size: 40px;
    line-height: 2.8rem;
    letter-spacing: 3px; }
  .footer-top-logo {
    color: #fff;
    font-family: Oswald, sans-serif;
    font-weight: 500;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 3px; }
    .footer-top-logo span {
      color: #fff;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 3px; }
  @media screen and (max-width: 768px) {
    .footer-top {
      padding-top: 4rem;
      padding-bottom: 2rem; } }

.footer-copyright {
  padding: 1rem 0;
  color: #fff;
  background: #141520;
  text-align: center;
  align-items: center;
  vertical-align: center; }
  .footer-copyright-lead {
    display: block;
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 300;
    text-align: center; }
  .footer-copyright-link,
  .footer-copyright a {
    display: inline-block;
    margin: 0;
    margin-left: 0.2rem;
    padding: 0;
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    font-size: 0.8rem;
    font-weight: 300; }
    .footer-copyright-link:hover, .footer-copyright-link:focus,
    .footer-copyright a:hover,
    .footer-copyright a:focus {
      text-decoration: underline; }

/* social 2 */
/*
ul.social-network {
  margin-bottom: 1rem;
  color: #fff;

  li {
  }
}*/
.site-footer .footer-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  text-decoration: none !important;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  color: #fafafa;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-in-out; }
  .site-footer .footer-social a:hover, .site-footer .footer-social a:active {
    color: #111;
    background-color: #fff;
    -webkit-tap-highlight-color: rgba(100, 0, 0, 0);
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    /*only to disable context menu on long press*/ }
  .no-touch .site-footer .footer-social a:hover,
  .touch .site-footer .footer-social a:active {
    text-decoration: none;
    outline: none; }

/**
 * File: home.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * Imports
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles element
 **/
#addo-page {
  width: 100%;
  overflow: hidden;
  position: relative; }

#addo-aside,
.portfolio-aside {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  display: block;
  width: 370px;
  padding: 4rem 2.5rem;
  z-index: 1001;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  transform: translateX(-370px);
  transition: 0.5s;
  scrollbar-width: none; }
  @media (min-width: 768.02px) {
    #addo-aside,
    .portfolio-aside {
      width: 20%;
      min-width: 300px;
      padding: 2.8rem 1.8rem;
      z-index: 999;
      transform: translateX(0); } }
  @media (min-width: 1800px) {
    #addo-aside,
    .portfolio-aside {
      width: 480px;
      max-width: 480px; } }
  #addo-aside #addo-logo, #addo-aside-logo,
  .portfolio-aside #addo-logo,
  .portfolio-aside-logo {
    display: block;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 1em; }
    #addo-aside #addo-logo span, #addo-aside-logo span,
    .portfolio-aside #addo-logo span,
    .portfolio-aside-logo span {
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      color: #888;
      font-weight: 400;
      letter-spacing: 3px; }
    @media screen and (max-width: 768px) {
      #addo-aside #addo-logo, #addo-aside-logo,
      .portfolio-aside #addo-logo,
      .portfolio-aside-logo {
        margin-bottom: 60px; } }

.page-portfolio.offcanvas .portfolio-aside {
  transform: translateX(0);
  width: 370px;
  z-index: 999;
  position: fixed; }

#addo-logo-footer {
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: block;
  width: 100%; }

#addo-aside-logo a,
.portfolio-aside-logo a {
  display: inline-block;
  text-align: center;
  color: #101010;
  letter-spacing: 3px; }
  #addo-aside-logo a:focus,
  .portfolio-aside-logo a:focus {
    outline: none; }
  #addo-aside-logo a:link,
  .portfolio-aside-logo a:link {
    text-decoration: none; }
  #addo-aside-logo a:hover, #addo-aside-logo a:active, #addo-aside-logo a:focus,
  .portfolio-aside-logo a:hover,
  .portfolio-aside-logo a:active,
  .portfolio-aside-logo a:focus {
    color: #101010;
    outline: none;
    text-decoration: none !important; }

#addo-logo-footer a {
  display: inline-block;
  text-align: center;
  color: #101010; }

#addo-aside-logo a span,
.portfolio-aside-logo a span {
  display: block;
  margin-top: 0px;
  text-align: center; }

#addo-aside #addo-main-menu ul,
.portfolio-aside #addo-main-menu ul {
  text-align: left;
  margin: 0;
  padding: 0; }
  #addo-aside #addo-main-menu ul li,
  .portfolio-aside #addo-main-menu ul li {
    margin: 15px 0 15px 0;
    padding: 0 0 10px 0;
    list-style: none;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.75em;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 1px solid #ececec; }
    #addo-aside #addo-main-menu ul li a,
    .portfolio-aside #addo-main-menu ul li a {
      color: #888;
      text-decoration: none;
      position: relative;
      padding: 0;
      font-family: "Oswald", sans-serif;
      font-weight: 400;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      #addo-aside #addo-main-menu ul li a:hover,
      .portfolio-aside #addo-main-menu ul li a:hover {
        text-decoration: none;
        color: #101010; }
    #addo-aside #addo-main-menu ul li.addo-active a,
    .portfolio-aside #addo-main-menu ul li.addo-active a {
      color: #101010; }
      #addo-aside #addo-main-menu ul li.addo-active a:after,
      .portfolio-aside #addo-main-menu ul li.addo-active a:after {
        visibility: visible;
        transform: scaleX(1); }

#addo-aside .portfolio-aside-footer,
.portfolio-aside .portfolio-aside-footer {
  position: absolute;
  bottom: 2rem;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 400; }
  @media (min-width: 1200px) {
    #addo-aside .portfolio-aside-footer,
    .portfolio-aside .portfolio-aside-footer {
      padding: 30px; } }

@media screen and (max-width: 768px) {
  #addo-aside #addo-main-menu ul {
    margin: 0 0 30px 0; } }

.portfolio-aside-footer .aside-social {
  display: inline-block;
  transition: all 0.5s; }
  .portfolio-aside-footer .aside-social .black-icon {
    color: #101010; }
    .portfolio-aside-footer .aside-social .black-icon:hover {
      color: #5584ff !important; }
  .portfolio-aside-footer .aside-social .list-inline-item:not(:last-child) {
    margin-right: 1rem; }

#addo-main-menu {
  cursor: pointer; }

.portfolio-aside {
  font-weight: 400; }

/**
 * File: buttons.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 *	Style
 **/
.btn-primary, .btn-secondary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 2.875rem;
  max-height: 2.875rem;
  min-width: 180px;
  padding-bottom: 13px;
  padding-top: 13px;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-out; }

.btn {
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit; }

.btn-primary {
  color: #fff;
  background: #007bff;
  background-color: #007bff;
  border: 0.0625rem solid #007bff;
  border-radius: 1.625rem; }

.btn-secondary {
  background: #666666;
  background-color: #666666;
  border: 0.0625rem solid;
  border-radius: 1.625rem; }

.btn-glass {
  box-shadow: 0 0 1rem 0 black;
  background: transparent;
  backdrop-filter: blur(2px); }

.btn-white {
  background: 0 0;
  border: 1px solid #fff;
  color: #fff; }

/**
 *	Style Modal Close Button
 **/
.modal-wrapper .modal-close-btn {
  position: absolute;
  top: -35px;
  right: -35px;
  width: 35px;
  height: 35px;
  display: inline-block;
  overflow: hidden;
  border: none;
  background: transparent; }
  .modal-wrapper .modal-close-btn button {
    cursor: pointer;
    appearance: unset;
    -webkit-appearance: unset;
    outline: none;
    -webkit-outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    opacity: 0; }
  .modal-wrapper .modal-close-btn:before {
    transform: rotate(45deg); }
  .modal-wrapper .modal-close-btn:after {
    transform: rotate(-45deg); }
  .modal-wrapper .modal-close-btn:before, .modal-wrapper .modal-close-btn:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
    width: 100%;
    margin-top: -1px;
    border-radius: 5px;
    margin-top: -6px;
    background: #cccccc; }
  .modal-wrapper .modal-close-btn:hover:before, .modal-wrapper .modal-close-btn:hover:after {
    background: #fff; }

/**
 *	Style _todel
 **/
.btn-custom {
  background-color: #3648ff;
  border: 2px solid #3648ff;
  color: #fff;
  transition: all 0.5s;
  border-radius: 5px;
  letter-spacing: 1px;
  text-transform: capitalize; }

.btn-rounded {
  border-radius: 30px !important; }

.btn-shine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap; }

@keyframes shine {
  0% {
    background-position: 0; }
  60% {
    background-position: 180px; }
  100% {
    background-position: 180px; } }

/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
.form-contact .input-container {
  width: 100%;
  max-width: 100%;
  margin-top: 1rem; }

.form-contact .form-group-input input,
.form-contact .form-group-input textarea {
  width: 100%;
  padding: 1.6rem;
  border: 0;
  border: 1px solid #ccc;
  font-size: 1rem;
  border-radius: 0.25rem; }

.form-contact .styled-input,
.form-contact .form-group-input {
  float: left;
  width: 100%;
  margin: 1rem 0;
  position: relative;
  border-radius: 0.25rem; }
  @media (min-width: 768.02px) {
    .form-contact .styled-input,
    .form-contact .form-group-input {
      width: 100%; } }
  .form-contact .styled-input label,
  .form-contact .form-group-input label {
    position: absolute;
    top: 0.5rem;
    left: 0;
    padding: 1rem 2rem;
    color: #999;
    pointer-events: none;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease; }
  .form-contact .styled-input.wide,
  .form-contact .form-group-input.wide {
    width: 100%;
    max-width: 100%; }

.form-contact input:focus ~ label,
.form-contact textarea:focus ~ label,
.form-contact input:required:valid ~ label,
.form-contact textarea:valid ~ label {
  top: -1rem;
  color: #999;
  font-size: 0.75rem;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease; }

.form-contact input:focus,
.form-contact textarea:focus {
  outline: 0; }

.form-contact input:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease; }

.form-contact input:invalid,
.form-contact textarea:invalid {
  border-bottom: 1px solid red;
  border-radius: 0.25rem; }

.form-contact textarea {
  width: 100%;
  min-height: 12em; }
  .form-contact textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease; }

.form-contact .form-group-checkbox {
  position: relative;
  /*
    .checkmark {
      position: absolute;
      top: 13px;
      left: 0;
      height: 15px;
      width: 15px;
      border: 1px solid #ccc;
      cursor: pointer;
    }

    input:checked ~ .checkmark::after {
      display: block;
    }

    .checkmark::after {
      content: "";
      position: absolute;
      left: 5px;
      top: 1px;
      width: 3px;
      height: 8px;
      border: 1px solid #3786bd;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      transform: rotate(45deg);
      display: none;
    }

    input {
      position: absolute;
      opacity: 0;
    }

    input {
      width: 100%;
      padding: 11.5px 15px;

      font-family: open sans, sans-serif;
      font-size: 15px;
      color: #333;

      border: 1px solid #e5e5e5;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;

      appearance: unset;
      -webkit-appearance: unset;

      outline: none;
      -webkit-outline: none;

      box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }*/ }
  .form-contact .form-group-checkbox p {
    margin-left: 2.5rem;
    text-align: left;
    line-height: 1.2; }
  .form-contact .form-group-checkbox input[type="checkbox"] {
    cursor: pointer;
    appearance: unset;
    -webkit-appearance: unset;
    outline: none;
    -webkit-outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box; }
    .form-contact .form-group-checkbox input[type="checkbox"]:before {
      position: absolute;
      top: 0.5rem;
      left: 1rem;
      width: 1rem;
      height: 1rem;
      content: "";
      display: block;
      border: 1px solid #ccc;
      border-radius: 3px; }
    .form-contact .form-group-checkbox input[type="checkbox"]:checked:after {
      position: absolute;
      top: 0.6rem;
      left: 1.3rem;
      width: 5px;
      height: 10px;
      content: "";
      display: block;
      border: solid;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }

.form-contact .form-group-submit {
  text-align: end; }

.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  overflow: hidden;
  list-style: none; }

span .tags li {
  float: left; }

.tag {
  position: relative;
  margin: 0 10px 10px 0;
  padding: 0 20px 0 23px;
  display: inline-block;
  color: #999;
  background: #eee;
  border-radius: 3px 0 0 3px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  -webkit-transition: color 0.2s; }
  .tag::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: "";
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px; }
  .tag::after {
    background: #fff;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #eee;
    border-top: 13px solid transparent;
    content: "";
    position: absolute;
    right: 0;
    top: 0; }
  .tag:hover {
    background-color: #000ee6;
    color: white; }
    .tag:hover::after {
      border-left-color: #000ee6; }

/**
 * File: ourpoliciesmodal.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: buttons.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 *	Style
 **/
.btn-primary, .btn-secondary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 2.875rem;
  max-height: 2.875rem;
  min-width: 180px;
  padding-bottom: 13px;
  padding-top: 13px;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-out; }

.btn {
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit; }

.btn-primary {
  color: #fff;
  background: #007bff;
  background-color: #007bff;
  border: 0.0625rem solid #007bff;
  border-radius: 1.625rem; }

.btn-secondary {
  background: #666666;
  background-color: #666666;
  border: 0.0625rem solid;
  border-radius: 1.625rem; }

.btn-glass {
  box-shadow: 0 0 1rem 0 black;
  background: transparent;
  backdrop-filter: blur(2px); }

.btn-white {
  background: 0 0;
  border: 1px solid #fff;
  color: #fff; }

/**
 *	Style Modal Close Button
 **/
.modal-wrapper .modal-close-btn {
  position: absolute;
  top: -35px;
  right: -35px;
  width: 35px;
  height: 35px;
  display: inline-block;
  overflow: hidden;
  border: none;
  background: transparent; }
  .modal-wrapper .modal-close-btn button {
    cursor: pointer;
    appearance: unset;
    -webkit-appearance: unset;
    outline: none;
    -webkit-outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    opacity: 0; }
  .modal-wrapper .modal-close-btn:before {
    transform: rotate(45deg); }
  .modal-wrapper .modal-close-btn:after {
    transform: rotate(-45deg); }
  .modal-wrapper .modal-close-btn:before, .modal-wrapper .modal-close-btn:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
    width: 100%;
    margin-top: -1px;
    border-radius: 5px;
    margin-top: -6px;
    background: #cccccc; }
  .modal-wrapper .modal-close-btn:hover:before, .modal-wrapper .modal-close-btn:hover:after {
    background: #fff; }

/**
 *	Style _todel
 **/
.btn-custom {
  background-color: #3648ff;
  border: 2px solid #3648ff;
  color: #fff;
  transition: all 0.5s;
  border-radius: 5px;
  letter-spacing: 1px;
  text-transform: capitalize; }

.btn-rounded {
  border-radius: 30px !important; }

.btn-shine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap; }

@keyframes shine {
  0% {
    background-position: 0; }
  60% {
    background-position: 180px; }
  100% {
    background-position: 180px; } }

/**
 * Variables
 **/
/**
 *	Style
 **/
.modal-wrapper .modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: initial !important;
  z-index: 10000; }

.modal-wrapper .modal-content-wrapper {
  position: relative;
  height: 80vh;
  width: 70%; }

.modal-wrapper .modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-y: scroll; }

.modal-wrapper .modal-footer {
  margin: 1rem 0;
  margin-right: 2rem;
  float: right; }

.modal-wrapper .modal-header {
  display: inline-block;
  margin-top: 1rem;
  margin-left: 2rem; }
  .modal-wrapper .modal-header .logo {
    float: left;
    margin-right: 2rem; }
  .modal-wrapper .modal-header .modal-title {
    float: left;
    margin-bottom: 0; }

.modal-wrapper .modal-body {
  padding: 1rem 1.5rem;
  border-top: 1px solid;
  border-bottom: 1px solid;
  text-align: left; }
  .modal-wrapper .modal-body h2 {
    padding-top: 1.125rem;
    font-size: 1.75rem;
    line-height: 2.375rem;
    font-weight: 700;
    letter-spacing: -0.0625rem;
    text-align: center; }
  .modal-wrapper .modal-body h3 {
    padding: 1.25rem 0 0.3125rem;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.75rem;
    letter-spacing: -0.03125rem; }

/**
 *	Typography Style
 **/
.modal-wrapper ol {
  list-style: none;
  counter-reset: li; }
  .modal-wrapper ol > li:before {
    counter-increment: li;
    content: counters(li, ".") ". "; }
  .modal-wrapper ol ul {
    list-style-type: disc;
    margin: 0; }

.modal-wrapper ol li {
  padding: 1.25rem 0 0;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.75rem;
  letter-spacing: -0.03125rem; }
  .modal-wrapper ol li ol {
    padding-top: 0.3125rem; }
    .modal-wrapper ol li ol li {
      padding: 0;
      font-size: 0.875rem;
      line-height: 1.5rem;
      letter-spacing: -0.03125rem; }

/*
ul.tab-nav {
  list-style: none;
  border-bottom: 1px solid #bbb;
  padding-left: 5px;

  li {
    display: inline;

    a,
    button {
      &.btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: -1px;
        border-bottom: none;
      }

      &.active.btn {
        border-bottom: 0.175em solid #fff;
      }
    }
  }
}
*/
.modal ul.tabs {
  padding: 0;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0;
  list-style-type: none;
  text-align: left;
  border-bottom: 1px solid; }
  .modal ul.tabs li {
    display: inline;
    margin: 0;
    margin-right: 0.25rem; }
    .modal ul.tabs li button {
      position: relative;
      top: 1px;
      padding: 0.5rem 1rem;
      color: #6c757d;
      background: url("../../images/tabbg.gif") 0 0 repeat-x;
      border: 1px solid;
      border-radius: 3px 3px 0 0;
      font: normal 1rem Arial, sans-serif;
      text-decoration: none;
      outline: none; }
      .modal ul.tabs li button:hover {
        position: relative;
        top: 1px;
        background: url("../../images/tabbg.gif") 0 -36px repeat-x;
        border: 1px solid; }
      .modal ul.tabs li button.active {
        position: relative;
        top: 1px;
        color: #333333;
        border: 1px solid;
        font-weight: bold; }
        .modal ul.tabs li button.active:hover {
          border: 1px solid;
          font-weight: bold; }

/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f0fafe;
  z-index: 3000;
  transition: opacity 0.3s; }
  .cookie-bar-glass {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px); }
  .cookie-bar-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem; }
    @media (min-width: 768.02px) {
      .cookie-bar-button {
        margin: 0;
        margin-left: 1.5rem; } }
  .cookie-bar-content button {
    margin-left: 0.25rem;
    /* margin-left: auto;
      margin-right: auto;
      margin-top: 0.5rem;

      @include media-breakpoint-up(md) {
        margin: 0;

      }*/ }
  .cookie-bar-content span {
    display: inline-block; }
  .cookie-bar-content img {
    width: 30px;
    margin-right: 1rem; }

.cookie-bar-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0 !important;
  padding-right: 0 !important; }
  @media (min-width: 768.02px) {
    .cookie-bar-container {
      flex-wrap: nowrap;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; } }

.cookie-bar {
  font-size: 75%; }

.group-contact-details {
  margin-bottom: 2rem; }

.group-buttons {
  text-align: center; }
  .group-buttons span:not(:last-child) {
    margin-right: 1.2rem; }

.Typewriter {
  display: inline-block; }

/**
 * File: experience.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 *	Style
 **/
.cv_edu::after, .cv_exp::after {
  content: "";
  position: absolute;
  top: 0;
  margin: 0;
  height: 100%;
  width: 1px;
  background-color: #5584ff; }

.cv_edu .cv_edu_box, .cv_exp .cv_exp_box {
  margin-right: 20px;
  padding: 20px;
  border: 1px solid #efefef;
  border-radius: 7px;
  box-shadow: 0px 4px 0px 2px rgba(0, 0, 0, 0.06); }

.cv_edu .cv_edu_box .cv_edu_year::after, .cv_exp .cv_exp_box .cv_exp_year::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  z-index: 1;
  background-color: #5584ff;
  border: 4px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #e6e6e6; }

.cv_desc {
  line-height: 26px;
  font-size: 15px; }

.vertical-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row; }

.cv_edu {
  padding: 10px 0;
  position: relative; }
  .cv_edu::after {
    left: 0; }
  .cv_edu .cv_edu_box {
    margin-left: 20px; }
    .cv_edu .cv_edu_box .cv_edu_year {
      position: relative;
      margin: 0 0 10px 15px !important; }
      .cv_edu .cv_edu_box .cv_edu_year::after {
        left: -65px !important; }
    .cv_edu .cv_edu_box .cb_edu_detail {
      text-align: left;
      margin-left: 15px;
      float: left;
      border-radius: 0; }
  .cv_edu h4 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
    text-transform: capitalize; }

.cb_edu_detail h4 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
  text-transform: capitalize; }

.cv_exp {
  padding: 10px 0;
  position: relative; }
  .cv_exp::after {
    right: 0; }
  .cv_exp .cv_exp_box {
    margin-right: 20px; }
    .cv_exp .cv_exp_box .cv_exp_year {
      position: relative;
      margin: 0 0 10px 15px !important; }
      .cv_exp .cv_exp_box .cv_exp_year::after {
        right: -51px !important; }
    .cv_exp .cv_exp_box .cb_exp_detail {
      text-align: right;
      margin-left: 15px;
      float: left;
      border-radius: 0; }
  .cv_exp h4 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
    text-transform: capitalize; }

.cb_exp_detail h4 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
  text-transform: capitalize; }

/**
 * File: contact.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 *	Style
 **/
.section-letscontact {
  background-image: url(../../../images/slide1.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed; }

.section-letscontact {
  position: relative; }

/**
 * File: blog.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.section-blog-preview {
  background: #f7f7f7; }

.section-blog-list {
  background: #f7f7f7; }

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 3rem;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border 0.2s ease-in-out; }

.thumbnail {
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0; }
  .thumbnail .card-img-top {
    width: 100%; }
  .thumbnail .card-body .card-title {
    font-weight: 700; }
  .thumbnail .card-footer {
    padding: 0;
    background-color: inherit;
    border-top: none; }
    .thumbnail .card-footer a {
      text-transform: uppercase; }

.scale {
  display: inline-block;
  overflow: hidden; }
  .scale img {
    transition: 1s;
    display: block; }
    .scale img:hover {
      transform: scale(1.2); }

.section-blog-list {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.blog-list-buttons {
  margin: 1rem 0;
  justify-content: flex-end; }

/**
 * File: blog.scss
 *
 * Option:
 * Author:
 * License:
*/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/*

	Margin / Padding Quick Resets

	example: top & bottom margin set to $spacing-unit
	.element {
		@include push--ends;
	}

	example: left & right padding set to $spacing-unit--small
	.element {
		@include soft--sides($spacing-unit--small);
	}

*/
/*

	Content margins

	fore removing first/last child margins

	example: default
	.element {
		@include content-margins;
	}

	output:
	.element > *:first-child {
		margin-top: 0;
	}
	.element > *:last-child {
		margin-bottom: 0;
	}

	example: empty selector
	.element {
		@include content-margins('false');
	}

	output:
	.element:first-child {
		margin-top: 0;
	}
	.element:last-child {
		margin-bottom: 0;
	}

*/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/**
 * File: mediaqueries.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: functions.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 * File: variables.scss
 *
 * Option:
 * Author:
 * License:
 **/
/**
 *	Themes
 **/
/**
 *	Media queries
 **/
/**
 *	Colors
 **/
/*
background-color: theme-color(); // "primary base" color;
boder: 1px solid theme-color('background', 'light'); // "foreground light" color;
*/
/*
$site-breakpoints: (
  sm: 576px,
  md: 765px,
  lg: 992px,
  xl: 1240px,
  xxl: 1800px,
);
*/
/**
 * Footer typography
 *
 **/
/**
 * File: utils.scss
 *
 * Option:
 * Author:
 * License:
 **/
/*

div::after {
    @include pseudo;
    top: -1rem; left: -1rem;
    width: 1rem; height: 1rem;
}
*/
/*div {
    @include responsive-ratio(16,9);
}
*/
/*
$breakpoints: (
  small : 480px,
  medium: 700px,
  large : 1024px
);

$p-font-sizes: (
  null  : (15px, 1.3),
  small : 16px,
  medium: (17px, 1.4),
  900px : 18px,
  large : (19px, 1.45),
  1440px: 20px,
);

p {
  @include font-size($p-font-sizes);
}
*/
/*

.site-header {
    z-index: z('site-header');
}
*/
/*
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
*/
/**
 * Variables
 **/
/**
 * Styles
 **/
.categories {
  padding: 0; }
  .categories li {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
    list-style: none; }
    .categories li:last-child {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0; }
    .categories li a {
      display: block;
      color: #000000; }
      .categories li a span {
        position: absolute;
        right: 0;
        top: 0;
        color: #ccc; }
    .categories li.active a {
      color: #000000;
      font-style: italic; }

.post-img {
  margin-bottom: 3rem; }

.post-title h2 {
  font-size: 1.7em;
  line-height: 1.3em;
  font-weight: 500;
  font-family: "Roboto", serif;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px; }

.post-detail .post-meta ul,
.post-detail .post-meta li {
  list-style: none;
  padding: 0px;
  margin: 0px; }

.post-detail .post-meta li {
  position: relative;
  color: #222222;
  font-weight: 400;
  font-size: 11px;
  padding-left: 18px;
  display: inline-block;
  margin-right: 20px;
  letter-spacing: 1px;
  text-transform: uppercase; }
  .post-detail .post-meta li .icon {
    position: absolute;
    left: 0px;
    top: 0px;
    font-size: 12px;
    color: #999999; }

.post-detail .post-content img {
  margin-bottom: 3rem;
  max-width: 100%;
  height: auto; }

.post-detail .post-content p {
  color: #888;
  font-size: 1rem;
  font-family: "Lora", serif;
  line-height: 1.929;
  font-size: 15px;
  margin-bottom: 1.4rem; }

.post-detail .post-content h1,
.post-detail .post-content h2,
.post-detail .post-content h3,
.post-detail .post-content .h1,
.post-detail .post-content .h2,
.post-detail .post-content .h3 {
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400; }

.post-detail .post-content h2,
.post-detail .post-content .h2 {
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 2rem; }

.post-footer {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem; }
  .post-footer .post-tag span {
    float: left;
    border: 1px solid #e1e1e1;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
    text-transform: uppercase; }
    .post-footer .post-tag span:hover {
      border-color: #000; }
  .post-footer .post-tag a {
    color: #7d7d7d; }

/**
 * Variables
 **/
